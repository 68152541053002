<template>
  <div id="app">
    <el-container direction="vertical">
      <Topbar />
      <TwoTop />
      <AppMain />
      <Playbar />
    </el-container>
    <el-backtop>
      <div class="backtop el-icon-upload2"></div>
    </el-backtop>
  </div>
</template>

<script>
import Topbar from '@/components/Topbar/Topbar'
import Playbar from '@/components/Playbar/Playbar'
import AppMain from '@/components/AppMain'
import TwoTop from '@/components/TwoTop'

export default {
  name: 'App',
  components: {
    AppMain,
    Topbar,
    Playbar,
    TwoTop
  }
}
</script>

<style scoped lang="scss">
#app {
  background-color: #ffffff;
  min-width: 1519px;
  .backtop {
    height: 100%;
    // width: 100%;
    background-color: #f2f5f6;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
    text-align: center;
    line-height: 40px;
    color: #31ab77;
    border-radius: 20px;
    font-size: 30px;
  }
}
</style>
