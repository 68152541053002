<template>
  <div class="content">
    <div class="box">
      <div class="left">
        <div class="leftimg"></div>
        <div class="leftletter">
          <div>24小时咨询服务热线</div>
          <div>400-658-3055</div>
        </div>
      </div>
      <div class="right">
        Copyright&nbsp;©&nbsp;2014-2020堆云健康版权所有&nbsp;&nbsp;ICP备案编号：京ICP备15020852-2<br />北京市海淀区高梁桥斜街42号院1号楼2层2-215
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Playbar',
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  background: #222222;
}
.box {
  margin: auto;
  height: 134px;
  width: 75%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    // width: 356px;
    height: 65px;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .leftimg {
    width: 58px;
    height: 58px;
    margin-right: 10px;
    background: url(../../assets/shouye/400.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .leftletter {
    div:first-child {
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      // line-height: 30px;
    }
    div:last-child {
      font-size: 33px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      // line-height: 44px;
      font-weight: bold;
    }
  }
  .right {
    text-align: right;
    font-size: 14px;
    font-family: MicrosoftSansSerif;
    color: #ffffff;
    line-height: 24px;
  }
}
</style>
