import instance from "@/api/instance";
import album from "./album";
import artist from "./artist";
import dj from "./dj";
import song from "./song";
import playlist from "./playlist";

export const timestamp = Date.now();
// 首页轮播图
export const getBanner = () =>
    instance({
      url: "/banner",
      method: "get",
    });

// 首页 获取推荐歌单 limit: 取出数量 , 默认为 30
export const getRecommendPlaylist = (limit) =>
    instance.get(`/personalized?limit=${limit}`);

// 搜索建议
export const searchSuggest = (keywords, ts) =>
    instance({
      url: `/search/suggest?timestamp=${ts}`,
      data: {
        keywords,
      },
      method: "post",
    });

/* ********* 排行榜 ********* */
// 排行榜
export const topList = () => instance.get("/toplist");
// 所有榜单内容摘要
export const topListDetail = () => instance.get("/toplist/detail");
// 歌单详情  #排行榜也是歌单的一种 s:最近收藏者
export const listDetail = (id = "", s = 8) =>
    instance.get(`/playlist/detail?id=${id}&s=${s}`);

/* ********* 评论 ********* */
// 评论接口
// id : 资源 id, 如歌曲 id,mv id
// type: 数字 , 资源类型 0: 歌曲 1: mv 2: 歌单 3: 专辑4: 电台5: 视频 6: 动态
// sortType: 排序方式, 1:按推荐排序, 2:按热度排序, 3:按时间排序
// cursor: 当sortType为 3 时且页数不是第一页时需传入,值为上一条数据的 time
export const reqComment = ({
                             id,
                             type,
                             pageNo = 1,
                             pageSize = 20,
                             sortType,
                             cursor,
                           }) => {
  if (sortType === 3 && pageNo !== 1) {
    return instance.get(
        `/comment/new?id=${id}&type=${type}&pageNo=${pageNo}&sortType=${sortType}&cursor=${cursor}&pageSize=${pageSize}`
    );
  } else {
    return instance.get(
        `/comment/new?id=${id}&type=${type}&pageNo=${pageNo}&sortType=${sortType}&pageSize=${pageSize}`
    );
  }
};

/* ********* 登录 ********* */
//手机登录
export const loginCellphone = (phone, password) => {
  return instance({
    url: "/login/cellphone",
    method: "post",
    params: {phone, password},
  });
};
// 获取用户详细
export const userDetail = (uid) => instance.get("/user/detail?uid=" + uid);
// 登录状态
export const loginStatus = () => instance.get("/login/status");
// 退出登录
export const logout = () => instance.get("/logout");

export default {
  searchSuggest,
  getBanner,
  getRecommendPlaylist,
  topList,
  topListDetail,
  listDetail,
  reqComment,
  loginCellphone,
  logout,
  ...album,
  ...artist,
  ...dj,
  ...playlist,
  ...song,
};
