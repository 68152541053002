import Vue from 'vue'
import VueRouter from 'vue-router'

// 引入nprogress #一款页面加载时进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    // 首页
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home/index'),
    meta: { title: '堆云健康-首页' }
  },
  {
    // 公司简介
    path: '/introduction',
    name: 'introduction',
    component: () => import('@/views/Introduction/index'),
    meta: { title: '堆云健康-公司简介' }
  },
  {
    // 新闻动态
    path: '/news',
    name: 'news',
    component: () => import('@/views/News/index'),
    meta: { title: '堆云健康-新闻动态' }
  },
  {
    // 新闻动态详情
    path: '/news-detail',
    name: 'news-detail',
    component: () => import('@/views/NewsDetail/index'),
    meta: { title: '堆云健康-新闻动态详情' }
  },
  {
    // 联系我们
    path: '/call',
    name: 'call',
    component: () => import('@/views/Call/index'),
    meta: { title: '堆云健康-联系我们' }
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    // 跳转路由时页面滚到顶部
    return { x: 0, y: 0 }
  }
})

// 路由跳转前钩子函数中 - 执行进度条开始加载
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.query.title) {
    document.title = to.query.title
  }
  NProgress.start()
  next()
})

// 路由跳转后钩子函数中 - 执行进度条加载结束
router.afterEach(() => {
  NProgress.done()
})

export default router
