<template>
  <div id="topbar">
    <div class="two" v-if="!isDetail">
      <div class="content">
        <span>堆云健康</span>
        <span>一站式 · 医学 · 管理中心方案和服务提供商</span>
        <span>“防脱+养护+药物+植发+假发”全病程</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwoTop',
  data() {
    return {
      isDetail: false
    }
  },
  watch: {
    '$route.path'(val) {
      this.isDetail = val === '/news-detail' ? true : false
    }
  }
}
</script>

<style scoped lang="scss">
#topbar {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  .two {
    width: 100%;
    height: 700px;
    background: url(../../assets/shouye/banner.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .content {
      width: 75%;
      margin: auto;
      span {
        display: block;
      }
      span:nth-child(1) {
        font-size: 100px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        font-weight: bold;
        margin-top: 183px;
      }
      span:nth-child(2) {
        font-size: 34px;
        font-family: FZLTXHJW--GB1-0, FZLTXHJW--GB1;
        font-weight: normal;
        color: #ffffff;
        margin: 15px auto;
      }
      span:nth-child(3) {
        font-size: 26px;
        font-family: FZLTXHJW--GB1-0, FZLTXHJW--GB1;
        font-weight: normal;
        color: #ffffff;
        opacity: 0.7;
      }
    }
  }
}
</style>
