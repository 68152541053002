import Vue from "vue";
import Vuex from "vuex";
import vuexAlong from "vuex-along";
import {loginCellphone, userDetail, logout} from "@/api";

Vue.use(Vuex);

const userAbout = {
  state: {
    userInfo: {},
    isLogin: false,
    active:'1'
  },
  actions: {
    async userLogin({commit}, {phone, password}) {
      let result = await loginCellphone(phone, password);
      if (result.data.code === 200) {
        let result2 = await userDetail(result.data.account.id)
        commit("USERLOGIN", result2.data);
        window.sessionStorage.setItem('token', result.data.token)
        window.sessionStorage.setItem('cookie', result.data.cookie)
        return "OK";
      }
      return new Error("fail");
    },

    // 退出登录
    async userLogout({commit}) {
      let result = await logout()
      if (result.data.code === 200) {
        commit("USERLOGOUT")
        window.sessionStorage.removeItem('token')
        window.sessionStorage.removeItem('cookie')
        return "OK"
      }
    },
  },
  mutations: {
    USERLOGIN(state, data) {
      Vue.set(state, "userInfo", data);
      Vue.set(state, "isLogin", true);
    },
    USERLOGOUT(state) {
      state.isLogin = false
      state.userInfo = {}
    },
    HANDLEACTIVE(state, val){
      state.active = val
    }
  },
  getters: {
    // 用户关注数
    follows(state) {
      return state.userInfo.profile ? state.userInfo.profile.follows : "";
    },
    // 用户粉丝数
    followeds(state) {
      return state.userInfo.profile ? state.userInfo.profile.followeds : 0;
    },
    // 用户头像
    userAvatarUrl: state => state.userInfo.profile ? state.userInfo.profile.avatarUrl : "",
    // 用户动态
    userEventCount: state => state.userInfo.profile ? state.userInfo.profile.eventCount : 0,
    // 用户昵称
    userNickname: state => state.userInfo.profile ? state.userInfo.profile.nickname : "",
    // 用户等级
    userLevel: state => state.userInfo.level || 0,
  }
};

export default new Vuex.Store({
  modules: {
    userAbout,
  },
  plugins: [vuexAlong]
});
