import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import router from "@/router";
// import 'element-ui/lib/theme-chalk/index.css'
import "@/styles/index.css"; // 引入初始化css
import "@/styles/element-#C20C0C/index.css"; // 引入自定义elementUI主题
import store from "@/store";
import loadingImg from '@/assets/lazy.png'
import VueLazyload from 'vue-lazyload'


Vue.use(VueLazyload, {
  loading: loadingImg,
})
Vue.use(ElementUI);

// 引入相关API请求接口
import API from "@/api";


Vue.config.productionTip = false;
new Vue({
  render: (h) => h(App),
  router,
  store,
  beforeCreate() {
    Vue.prototype.$bus = this; // 注册全局事件总线
    Vue.prototype.$API = API;
  },
}).$mount("#app");
