import instance from "./instance";

export default {
  // 获取歌曲详情
  songDetail: (ids) => instance.get(`/song/detail?ids=${ids}`),
  // 获取歌曲歌词
  songLyric: (id) => instance.get(`/lyric?id=${id}`),
  // 获取相似歌单
  simiPlaylist: (id) => instance.get(`/simi/playlist?id=${id}`),
  // 获取相似歌曲
  simiSong: (id) => instance.get(`/simi/song?id=${id}`),
}